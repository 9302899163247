/*******************************************************************************
 * (C) Copyright 2022, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactNode } from "react";
import { AppBar, Box, BoxProps, Stack, SxProps } from "@mui/material";

interface BasePageProps extends Omit<BoxProps, "title"> {
  titleLeft?: ReactNode;
  title?: ReactNode;
  titleRight?: ReactNode;
  headerSx?: SxProps;
}

export default function BasePage({
  titleLeft,
  title,
  titleRight,
  headerSx,
  children,
  ...props
}: BasePageProps) {
  return (
    <Stack height="100vh" {...props}>
      <AppBar position="static">
        <Stack direction="row" sx={headerSx}>
          <Box flex="1">{titleLeft}</Box>
          {title && <Box>{title}</Box>}
          <Box flex="1" display="flex" justifyContent="flex-end">
            {titleRight}
          </Box>
        </Stack>
      </AppBar>
      {children}
    </Stack>
  );
}
