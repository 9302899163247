/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Block as DisabledIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Refresh as RefreshIcon,
  LockOpen as PasswordIcon,
  Replay as ResendIcon,
  ChevronLeft as BackIcon,
} from "@mui/icons-material";
import { useQuery } from "react-query";
import capitalize from "lodash/capitalize";

import { constants } from "../../App";
import { useAuthContext } from "../../components/AuthContext";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Table, TableHeader, TableRow } from "../../components/Table";

import DeleteModal from "./DeleteModal";
import UserModal from "./UserModal";

import {
  listUsers,
  adminEnableUser,
  adminDisableUser,
  adminExpireUserPassword,
  resendPasswordConfirmation,
} from "../../lib/usersapi";
import Page from "../Page";
import { User, UserPool } from "../../lib/backend/users";
import { Centered } from "../../components/Centered";
import { useLocation, useNavigate } from "react-router-dom";
import DateTime from "../../components/DateTime";
import { FilterBox } from "../Common";

const STATUS_COLOR_MAP: { [type: string]: string } = {
  UNCONFIRMED: "#FFFF00",
  CONFIRMED: "#00FF00",
  ARCHIVED: "#919191",
  COMPROMISED: "#FF0000",
  UNKNOWN: "#919191",
  RESET_PASSWORD: "#FFFF00",
  FORCE_CHANGE_PASSWORD: "#FFFF00",
};

function UserTableRow({
  poolId,
  user,
  updating,
  onEdit,
  onUpdating,
}: {
  poolId: string;
  user: User;
  updating: boolean;
  onEdit: (user: User) => any;
  onUpdating: (value: boolean, refetch?: boolean) => any;
}) {
  const auth = useAuthContext();
  const currentUser = auth.user!;

  const [enabling, setEnabling] = useState<boolean>(false);
  const [deleteUser, setDeleteUser] = useState<User | null>(null);
  const [expiring, setExpiring] = useState<boolean>(false);
  const [resending, setResending] = useState<boolean>(false);

  function handleStateChange(value: boolean) {
    if (
      window.confirm((value ? "Enable" : "Disable") + " user, are you sure?")
    ) {
      onUpdating(true);
      setEnabling(true);
      const prom = value
        ? adminEnableUser(currentUser.token, currentUser.poolId, user.email)
        : adminDisableUser(currentUser.token, currentUser.poolId, user.email);
      prom
        .then(() => {
          onUpdating(false, true);
        })
        .catch((error) => {
          alert(JSON.stringify(error));
          onUpdating(false);
        });
    }
  }

  function handleExpire(user: User) {
    if (window.confirm("Expire " + user.name + "'s password, are you sure?")) {
      onUpdating(true);
      setExpiring(true);
      adminExpireUserPassword(currentUser.token, poolId, user.email)
        .then(() => {
          onUpdating(false, true);
        })
        .catch((error) => {
          alert(JSON.stringify(error));
          onUpdating(false);
        })
        .finally(() => {
          setExpiring(false);
        });
    }
  }

  function handleResendConfirm(user: User) {
    if (
      window.confirm("Resend " + user.name + "'s confirmation, are you sure?")
    ) {
      onUpdating(true);
      setResending(true);
      resendPasswordConfirmation(
        currentUser.token,
        constants.UX_COGNITO_WEB_CLIENT,
        user.email
      )
        .then(() => {
          onUpdating(false, true);
        })
        .catch((error) => {
          alert(JSON.stringify(error));
          onUpdating(false);
        })
        .finally(() => {
          setResending(false);
        });
    }
  }

  useEffect(() => {
    setEnabling(false);
  }, [user]);

  return (
    <TableRow>
      <DeleteModal
        poolId={poolId}
        user={deleteUser}
        onClose={(refetch) => {
          onUpdating(false, refetch);
        }}
      />
      <Box>
        {enabling ? (
          <CircularProgress />
        ) : (
          <Tooltip
            title={user.enabled ? "Enabled" : "Disabled"}
            placement="left-start"
          >
            {user.enabled ? (
              <IconButton
                size="small"
                style={{ backgroundColor: "#515151" }}
                onClick={() => {
                  handleStateChange(false);
                }}
              >
                <CheckIcon style={{ color: "#00ff00" }} />
              </IconButton>
            ) : (
              <IconButton
                size="small"
                style={{ backgroundColor: "#515151" }}
                onClick={() => {
                  handleStateChange(true);
                }}
              >
                <DisabledIcon style={{ color: "#a1a1a1" }} />
              </IconButton>
            )}
          </Tooltip>
        )}
      </Box>
      <Box
        onClick={() => {
          onEdit(user);
        }}
      >
        {user.name.split(" ", 2).reverse().join(", ")}
      </Box>
      <Box
        onClick={() => {
          onEdit(user);
        }}
      >
        {user.email}
      </Box>
      <Box
        sx={{
          color: STATUS_COLOR_MAP[user.status],
        }}
      >
        {capitalize(user.status.replace(/_/g, " "))}
      </Box>
      <Box>
        <DateTime datetime={user.createDate} />
      </Box>
      <Box>
        {deleteUser ? (
          <CircularProgress />
        ) : (
          <IconButton
            size="small"
            disabled={updating || user.email === currentUser.email}
            //style={{ marginRight: "8px" }}
            onClick={() => {
              onUpdating(true);
              setDeleteUser(user);
            }}
          >
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        )}
        {expiring ? (
          <CircularProgress />
        ) : (
          <IconButton
            size="small"
            disabled={updating || user.status !== "CONFIRMED"}
            onClick={() => {
              handleExpire(user);
            }}
          >
            <Tooltip title="Expire password">
              <PasswordIcon />
            </Tooltip>
          </IconButton>
        )}
        {resending ? (
          <CircularProgress />
        ) : (
          <IconButton
            size="small"
            disabled={updating || user.status === "CONFIRMED"}
            onClick={() => {
              handleResendConfirm(user);
            }}
          >
            <Tooltip title="Resend confirmation">
              <ResendIcon />
            </Tooltip>
          </IconButton>
        )}
        <IconButton
          size="small"
          disabled={updating}
          onClick={() => {
            onEdit(user);
          }}
        >
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
      </Box>
    </TableRow>
  );
}

function UserTable({
  poolId,
  users,
  updating,
  onEdit,
  onUpdating,
}: {
  poolId: string;
  users: User[];
  updating: boolean;
  onEdit: (user: User) => any;
  onUpdating: (value: boolean, refetch?: boolean) => any;
}) {
  if (users.length === 0) {
    return (
      <Box sx={{ paddingTop: "48px", textAlign: "center" }}>
        No matching users
      </Box>
    );
  }

  return (
    <Table
      sx={{
        marginTop: "8px",
        gridTemplateColumns: "min-content 2fr 2fr 120px 100px min-content",
        "& > :nth-of-type(6n + 2).cell, & > :nth-of-type(6n + 3).cell": {
          cursor: "pointer",
        },
        "& > :nth-of-type(6n + 5)": {
          justifyContent: "flex-end",
          "& .cell": {
            fontSize: "0.75rem",
          },
        },
      }}
      onRefresh={() => {
        return onUpdating(false, true);
      }}
    >
      <TableHeader>
        <div />
        <div>Name</div>
        <div>Email</div>
        <div>Status</div>
        <div>Created</div>
        <div />
      </TableHeader>
      {users &&
        users.map((user) => (
          <UserTableRow
            key={user.email}
            poolId={poolId}
            user={user}
            updating={updating}
            onEdit={onEdit}
            onUpdating={onUpdating}
          />
        ))}
    </Table>
  );
}

function AddUserButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: (value: string) => any;
}) {
  return (
    <Button
      style={{ marginLeft: "12px" }}
      disabled={disabled}
      onClick={() => {
        onClick("new");
      }}
    >
      Add User
    </Button>
  );
}

function RefreshButton({
  disabled,
  onClick,
}: {
  disabled: boolean;
  onClick: () => any;
}) {
  return (
    <Tooltip title="Refresh">
      <IconButton size="small" disabled={disabled} onClick={onClick}>
        <RefreshIcon />
      </IconButton>
    </Tooltip>
  );
}

export default function Users() {
  const auth = useAuthContext();
  const currentUser = auth.user!;
  const [pool, setPool] = useState<UserPool | null>(null);
  const [searchValue, setSearchValue] = useState<string>("");
  const [editUser, setEditUser] = useState<User | string | null>(null);
  const [updating, setUpdating] = useState<boolean>(false);
  const [unauthorized, setUnauthorized] = useState<boolean>();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: users,
    isLoading,
    refetch: refetchUsers,
  } = useQuery<User[] | null>(
    ["users", pool && pool.id],
    () => {
      if (!currentUser || !pool) {
        return null;
      }
      setUpdating(true);
      return listUsers(currentUser.token, pool.id);
    },
    {
      enabled: Boolean(pool),
      refetchOnWindowFocus: false,
      retry: false,
      select: (data) => {
        if (!data) {
          return data;
        }
        return data.sort((val1, val2) => {
          return val1.name.split(" ", 2)[1] < val2.name.split(" ", 2)[1]
            ? -1
            : 1;
        });
      },
      onError: (error: any) => {
        if (error.statusCode === 401) {
          return setUnauthorized(true);
        }
        alert("Error loading users: " + JSON.stringify(error));
      },
      onSettled: (data) => {
        setUpdating(false);
      },
    }
  );

  function handleUpdating(value: boolean, refetch?: boolean) {
    setUpdating(value);
    if (refetch) {
      refetchUsers();
    }
  }

  useEffect(() => {
    const state = location.state || {};
    const pool = state.pool;
    if (!pool) {
      navigate("/", { replace: true });
      return;
    }
    setPool(pool);
  }, [location]);

  if (!pool) {
    return null;
  }

  return (
    <Page>
      <UserModal
        pool={pool}
        user={editUser}
        onClose={(refetch) => {
          setEditUser(null);
          if (refetch) {
            refetchUsers();
          }
        }}
      />
      {unauthorized ? (
        <Centered text="Unauthorized" />
      ) : !users ? (
        <LoadingSpinner />
      ) : (
        <Stack
          alignSelf="center"
          flex="1"
          width="100%"
          minWidth="600px"
          maxWidth="800px"
          overflow="hidden"
        >
          <Stack direction="row">
            <Stack direction="row" flex="1" alignItems="center">
              {currentUser.customer === constants.ADMIN_POOL && (
                <IconButton
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <Tooltip title="Back to pools">
                    <BackIcon />
                  </Tooltip>
                </IconButton>
              )}
              <Typography fontSize="1.25rem" fontWeight="bold">
                {capitalize(pool.name)}
              </Typography>
            </Stack>
            <FilterBox value={searchValue} onChange={setSearchValue} />
            <Box flex="1" display="flex" justifyContent="flex-end">
              <Button
                disabled={isLoading || updating}
                onClick={() => {
                  setEditUser("new");
                }}
              >
                Add User
              </Button>
            </Box>
          </Stack>
          {users.length === 0 ? (
            <Centered>
              <Box display="flex" alignItems="center" fontSize="1rem">
                There are no users in this pool
                <AddUserButton disabled={updating} onClick={setEditUser} />
              </Box>
              <Box>
                <RefreshButton disabled={updating} onClick={refetchUsers} />
              </Box>
            </Centered>
          ) : (
            <UserTable
              poolId={pool.id}
              users={users.filter((user) => {
                if (!searchValue) return true;
                const search = searchValue.toLowerCase();
                return (
                  !searchValue ||
                  user.name.toLowerCase().includes(search) ||
                  user.email.toLowerCase().includes(search)
                );
              })}
              updating={updating}
              onEdit={(user) => {
                setEditUser(user);
              }}
              onUpdating={handleUpdating}
            />
          )}
        </Stack>
      )}
    </Page>
  );
}
