/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React from "react";
import { Box, BoxProps, Typography } from "@mui/material";

interface CenteredProps extends BoxProps {
  text?: string;
}

export function Centered({ text, children, ...props }: CenteredProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      margin="auto"
      {...props}
    >
      {text ? <Typography fontSize="1.25rem">{text}</Typography> : children}
    </Box>
  );
}
