/*******************************************************************************
 * (C) Copyright 2021-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, {
  Children,
  ReactNode,
  ReactElement,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
} from "react";
import {
  Box,
  BoxProps,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import { debounce, merge } from "lodash";

import { ScrollState } from "../lib/libInfiniteScroll";
import { useLocal } from "../lib/utils";

/*******************************************************************************
 * This must be added to the top level somewhere
 * */
declare module "@mui/material/styles" {
  interface Theme {
    westell: any;
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    westell: any;
  }
}

/*******************************************************************************
 * Wrapper around a table header
 */
export function TableHeader({ children }: { children: ReactNode }) {
  return (
    <>
      {Children.toArray(children).map((child, idx) => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement, {
            key: idx,
            className: clsx(child.props.className, "header", "sticky"),
            ...child.props,
          });
        } else {
          return child;
        }
      })}
    </>
  );
}

/*******************************************************************************
 * Wrapper around a table row
 */
export function TableRow({
  nohover,
  children,
}: {
  nohover?: boolean;
  children: ReactNode;
}) {
  const [hover, setHover] = useState<string | null>();

  return (
    <>
      {Children.toArray(children).map((child, idx) => {
        if (isValidElement(child)) {
          return cloneElement(child as ReactElement, {
            key: idx,
            className: clsx("cell", hover, child.props.className),
            ...(!nohover && {
              onMouseEnter: () => {
                setHover("hover");
              },
              onMouseLeave: () => {
                setHover(null);
              },
            }),
          });
        } else {
          return child;
        }
      })}
    </>
  );
}

/*******************************************************************************
 * Visual data table.
 */
interface ScrollEvent {
  progress: number;
}

interface TableProps extends Omit<BoxProps, "onScroll"> {
  scroll?: boolean;
  containerProps?: BoxProps;
  tableRef?: MutableRefObject<HTMLDivElement | undefined>;
  onScroll?: (event: ScrollEvent) => void;
  onRefresh?: () => Promise<any>;
}

export function Table({
  scroll = true,
  sx,
  containerProps,
  tableRef = useRef<HTMLDivElement>(),
  onScroll,
  onRefresh,
  children,
  ...props
}: TableProps) {
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const theme = useTheme();

  const handleScroll = debounce((evt: Event) => {
    if (!onScroll) {
      return;
    }
    const comp = evt.target as HTMLDivElement;
    const progress = comp.scrollTop / (comp.scrollHeight - comp.offsetHeight);
    onScroll({ progress: Math.round(progress * 1000) / 10 });
  }, 50);

  useEffect(() => {
    if (onScroll) {
      if (tableRef && tableRef.current) {
        tableRef.current.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (tableRef && tableRef.current) {
          tableRef.current.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, []);

  return (
    <Stack
      overflow="hidden"
      {...containerProps}
      {...(props.minWidth && { minWidth: props.minWidth })}
    >
      <Box
        ref={tableRef}
        sx={merge(
          {
            display: "grid",
            "& > div": {
              display: "flex",
              alignItems: "center",
            },
          },
          theme.westell && theme.westell.table,
          scroll && {
            overflowX: "hidden",
            overflowY: "auto",
            "& > .header": {
              top: 0,
              zIndex: 1,
            },
          },
          sx
        )}
        {...props}
      >
        {children}
      </Box>
      {onRefresh && (
        <Box
          //flex="1"
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-end"
          margin="4px 0"
        >
          {refreshing ? (
            <Box padding="6px">
              <CircularProgress size={24} />
            </Box>
          ) : (
            <Tooltip title="Refresh" placement="left">
              <IconButton
                size="small"
                onClick={() => {
                  const prom = onRefresh();
                  if (prom) {
                    setRefreshing(true);
                    prom.then(() => {
                      setRefreshing(false);
                    });
                  }
                }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
    </Stack>
  );
}

function LoadingRow() {
  return (
    <TableRow>
      <Box gridColumn="1/-1" margin="auto">
        <Box paddingTop="4px">
          <CircularProgress />
        </Box>
      </Box>
    </TableRow>
  );
}

/*******************************************************************************
 * InfiniteScrollTable
 */
interface InfiniteScrollTableProps extends Omit<TableProps, "scroll"> {
  scrollState: ScrollState;
  loadMore: () => any;
}

export function InfiniteScrollTable({
  scrollState,
  loadMore,
  children,
  ...props
}: InfiniteScrollTableProps) {
  const scrollStateRef = useLocal<ScrollState>(scrollState);
  return (
    <Table
      scroll={true}
      onScroll={(event) => {
        if (event.progress > 80 && scrollStateRef().hasMore) {
          loadMore && loadMore();
        }
      }}
      {...props}
    >
      {scrollState.first > 0 && <LoadingRow />}
      {children}
      {scrollState.hasMore && <LoadingRow />}
    </Table>
  );
}
