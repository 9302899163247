/*******************************************************************************
 * (C) Copyright 2020, 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Stack } from "@mui/material";
import {
  VpnKey as CodeIcon,
  Lock as PasswordIcon,
  Person as UserIcon,
} from "@mui/icons-material";
import { Auth } from "@aws-amplify/auth";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Link } from "../../components/Link";
import { useAuthContext } from "../../components/AuthContext";
import { useValidation } from "../../components/ValidationTextField";

import { AuthCard, AuthError, AuthField, AuthForms } from "./Common";

export default function ChangePasswordForm({
  handleAuth,
  challengeUser,
}: {
  handleAuth: (authProm: Promise<any>) => Promise<any>;
  challengeUser?: any;
}) {
  const { changePassword, logout } = useAuthContext();
  const [method, setMethod] = useState<string>();
  const [values, setValues] = useState<{ [name: string]: string }>({});
  const [sending, setSending] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  const validation = useValidation("LoginForm");
  const navigate = useNavigate();

  function submit() {
    if (validation.hasErrors) {
      validation.setVisible(true);
      return;
    }

    setSending(true);

    let prom: Promise<any>;
    switch (method) {
      case AuthForms.CodeChangePassword:
        prom = Auth.forgotPasswordSubmit(
          values.email,
          values.code,
          values.password
        );
        break;
      case AuthForms.NewPassword:
        prom = Auth.completeNewPassword(challengeUser, values.password);
        break;
      default:
        prom = changePassword(values.previousPass, values.password);
        break;
    }

    handleAuth(prom)
      .then((result) => {
        setSuccess(true);
      })
      .catch((err) => {
        const error = err as AuthError;
        setMessage(error.message);
        setSending(false);
      });
  }

  function setValue(name: string, value: string) {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  useEffect(() => {
    const usernameVal = searchParams.get("username");
    if (usernameVal) {
      setValue("username", usernameVal);
    }
    const meth = searchParams.get("method");
    if (meth) {
      setMethod(meth);
    }
    setLoaded(true);
  }, [searchParams]);

  if (!loaded) {
    return null;
  }

  return (
    <AuthCard
      title={
        method === AuthForms.NewPassword ? "New Password" : "Reset Password"
      }
      error={message}
      success={success}
    >
      {method === AuthForms.CodeChangePassword ? (
        <>
          <AuthField
            name="email"
            label="Email Address"
            value={values.email || ""}
            required
            emailAddress
            validation={validation}
            autoFocus={!Boolean(values.email)}
            disabled={sending}
            startAdornmentIcon={<UserIcon />}
            onChangeValue={(_, value) => {
              setMessage(undefined);
              setValue("email", value);
            }}
            onEnter={submit}
          />
          <AuthField
            name="code"
            label="Confirmation code"
            value={values.code}
            required
            number
            minLength={4}
            validation={validation}
            autoFocus={Boolean(values.username)}
            disabled={sending}
            startAdornmentIcon={<CodeIcon />}
            onChangeValue={(_, value) => {
              setMessage(undefined);
              setValue("code", value);
            }}
            onEnter={submit}
          />
        </>
      ) : (
        method === AuthForms.ChangePassword && (
          <AuthField
            name="oldpassword"
            label="Previous password"
            type="password"
            value={values.previousPass}
            required
            minLength={8}
            validation={validation}
            disabled={sending}
            autoFocus={method !== AuthForms.CodeChangePassword}
            startAdornmentIcon={<PasswordIcon />}
            onChangeValue={(_, value) => {
              setMessage(undefined);
              setValue("previousPass", value);
            }}
            onEnter={submit}
          />
        )
      )}
      <AuthField
        name="password"
        label="Password"
        type="password"
        value={values.password}
        required
        minLength={8}
        match={values.password2}
        validation={validation}
        startAdornmentIcon={<PasswordIcon />}
        disabled={sending}
        onChangeValue={(_, value) => {
          setMessage(undefined);
          setValue("password", value);
        }}
        onEnter={submit}
      />
      <AuthField
        name="confirmation"
        label="Confirmation password"
        type="password"
        required
        minLength={8}
        value={values.password2}
        match={values.password}
        validation={validation}
        startAdornmentIcon={<PasswordIcon />}
        disabled={sending}
        onChangeValue={(_, value) => {
          setMessage(undefined);
          setValue("password2", value);
        }}
        onEnter={submit}
      />
      <Stack alignItems="center">
        <Button
          sx={{
            width: "60%",
            margin: "18px 0 12px 0",
          }}
          onClick={submit}
        >
          {sending ? (
            <CircularProgress size={24} sx={{ color: "white" }} />
          ) : method === AuthForms.NewPassword ? (
            "Set Password"
          ) : (
            "Reset Password"
          )}
        </Button>
        <Stack direction="row">
          <Link
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Link>
          {method === AuthForms.CodeChangePassword && (
            <Link sx={{ paddingLeft: "24px" }} onClick={() => {}}>
              Resend code
            </Link>
          )}
        </Stack>
      </Stack>
    </AuthCard>
  );
}
