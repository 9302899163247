/*******************************************************************************
 * (C) Copyright 2020-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactElement, useEffect } from "react";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  darkScrollbar,
} from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";

import Content from "./Content";
import { AuthProvider, useAuthContext } from "./components/AuthContext";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#303030",
      paper: "#484848",
    },
    primary: {
      main: "#00cccc",
    },
    secondary: {
      main: "#3f51b5",
    },
    warning: {
      main: "#aa0000",
      dark: "#ab003c",
    },
  },
  westell: {
    table: {
      gridTemplateRows: "30px",
      gridAutoRows: "1fr",
      backgroundColor: "background.paper",
      borderRadius: "4px",
      "& > div": {
        padding: "1px 8px",
      },
      "& > .header": {
        fontSize: "1rem",
        fontWeight: "bold",
        backgroundColor: "#b1b1b1",
        color: "#414141",
      },
      "& > .cell": {
        fontSize: "0.875rem",
        borderBottom: "1px solid #515151",
        "&.hover": {
          backgroundColor: "#606060",
        },
      },
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 28,
        thickness: 5,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          fontSize: "1.25rem",
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "#424242",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        InputLabelProps: {
          style: {
            color: "#fff",
            top: "-2px",
          },
        },
      },
      styleOverrides: {
        root: {
          backgroundColor: "#484848",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        size: "small",
        style: {
          backgroundColor: "#484848",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: (theme) => ({
        body:
          theme.palette.mode === "light"
            ? darkScrollbar({
                track: "#424242",
                thumb: "#00ffff",
                active: "#00ffff",
              })
            : {
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#424242",
                  width: "12px",
                  height: "12px",
                },
                "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                  borderRadius: 4,
                  backgroundColor: "#00ffff",
                  minHeight: 4,
                  border: "1px solid #00ffff",
                },
                "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
                  {
                    backgroundColor: "#00ffff",
                  },
                "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
                  {
                    background:
                      "linear-gradient(90deg, rgba(41,179,179,1) 0%, rgba(0,255,255,1) 50%, rgba(140, 255, 255, 1) 100%)",
                  },
                "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
                  {
                    backgroundColor: "#00ffff",
                  },
                "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                  backgroundColor: "#424242",
                },
              },
        a: {
          "&:link, &:visited, &:hover, &:active": {
            color: "inherit",
          },
        },
      }),
    },
  },
});

export const constants: { [name: string]: string } = {
  ADMIN_POOL: "westell",
  UX_NAME: "user_admin",
  UX_DISPLAY_NAME: "User Management",
  UX_ACCOUNT: process.env.UX_ACCOUNT!,
  UX_STACK_REGION: process.env.UX_STACK_REGION!,
  UX_COGNITO_USER_POOL: process.env.UX_COGNITO_USER_POOL!,
  UX_COGNITO_WEB_CLIENT: process.env.UX_COGNITO_WEB_CLIENT!,
};

function validate() {
  var valid = true;
  for (var key of Object.keys(constants)) {
    if (constants[key] === undefined) {
      console.log("Must define " + key + " in environment!");
      valid = false;
    }
  }
  return valid;
}

const isEnvValid = validate();
const queryClient = new QueryClient();

function ContentWrapper({ children }: { children: ReactElement }) {
  const { user } = useAuthContext();

  useEffect(() => {
    if (!user) {
      queryClient.clear();
    }
  }, [user]);

  return children;
}

export default function App() {
  if (!isEnvValid) {
    return <div>Invalid environment</div>;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ContentWrapper>
            <Content />
          </ContentWrapper>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}
