/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { Clear as DeleteIcon } from "@mui/icons-material";

import { User } from "../../lib/backend/users";
import { ValidationTextField } from "../../components/ValidationTextField";
import { useAuthContext } from "../../components/AuthContext";

import { adminDeleteUser } from "../../lib/usersapi";

interface DeleteDialogProps {
  poolId: string;
  user: User | null;
  onClose: (refetch?: boolean) => any;
}

export default function DeleteDialog({
  poolId,
  user,
  onClose,
}: DeleteDialogProps) {
  const auth = useAuthContext();
  const currentUser = auth.user!;

  const [confirmText, setConfirmText] = useState<string>("");
  const [deleting, setDeleting] = useState<boolean>(false);

  function handleDelete() {
    // This shouldn't happen
    if (!user) {
      return;
    }

    setDeleting(true);
    adminDeleteUser(currentUser.token, poolId, user.email)
      .then(() => {
        onClose(true);
      })
      .catch((error) => {
        alert(JSON.stringify(error));
        onClose();
      })
      .finally(() => {
        setDeleting(false);
      });
  }

  if (!user) return null;

  return (
    <Modal
      open={Boolean(user)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "100%",
        maxWidth: "100%",
      }}
    >
      <Card sx={{ width: "500px" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ background: "white" }}>
              <DeleteIcon sx={{ color: "#585656" }} />
            </Avatar>
          }
          title="Delete User"
        />
        <CardContent>
          <Stack paddingTop="8px" alignItems="center">
            <Typography>
              To remove{" "}
              <span style={{ color: "#00ffff" }}>
                {user.name} ({user.email})
              </span>
              ,
            </Typography>
            <Typography>
              enter <b>DELETE</b> in the box below
            </Typography>
            <ValidationTextField
              name="confirm"
              value={confirmText}
              margin="normal"
              autoFocus
              onChangeValue={(name, value) => {
                setConfirmText(value);
              }}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-evenly"
            paddingTop="16px"
          >
            <Button
              disabled={deleting}
              sx={{ width: "120px" }}
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={confirmText !== "DELETE"}
              sx={{ width: "120px", marginLeft: "16px" }}
              onClick={handleDelete}
            >
              {deleting ? <CircularProgress /> : "Delete"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
