/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React from "react";
import { CircularProgress, CircularProgressProps } from "@mui/material";

import { Centered } from "./Centered";

interface LoadingSpinnerProps extends CircularProgressProps {
  text?: string;
  fontSize?: number | string;
}

/*******************************************************************************
 * LoadingSpinner
 */
export function LoadingSpinner({
  text,
  fontSize = "1.25rem",
  ...props
}: LoadingSpinnerProps) {
  return (
    <Centered fontSize={fontSize}>
      {text || "Loading"}
      <CircularProgress sx={{ marginLeft: "12px" }} {...props} />
    </Centered>
  );
}
