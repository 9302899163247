/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import React from "react";
import {
  Box,
  BoxProps,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { ValidationTextField } from "../components/ValidationTextField";

interface FilterBoxProps extends Omit<BoxProps, "onChange"> {
  value: string;
  disabled?: boolean;
  onChange: (value: string) => any;
}
export function FilterBox({
  value,
  disabled,
  onChange,
  ...props
}: FilterBoxProps) {
  return (
    <Box display="flex" alignItems="center" {...props}>
      <ValidationTextField
        name="filter"
        placeholder="Filter"
        value={value}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                disabled={!Boolean(value)}
                sx={{
                  position: "absolute",
                  right: "0",
                }}
                onClick={() => {
                  onChange("");
                }}
              >
                <CloseIcon sx={{ fill: "lightgrey" }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          minWidth: "300px",
        }}
        onChangeValue={(name, value) => {
          onChange(value);
        }}
      />
    </Box>
  );
}
