/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, Stack, Tooltip } from "@mui/material";
import { Edit as EditIcon, PeopleAlt as UsersIcon } from "@mui/icons-material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "../../components/AuthContext";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Table, TableHeader, TableRow } from "../../components/Table";
import DateTime from "../../components/DateTime";

import { listUserPools, getUserPool } from "../../lib/usersapi";
import { UserPool } from "../../lib/backend/users";
import { Centered } from "../../components/Centered";
import Page from "../Page";
import { FilterBox } from "../Common";
import { constants } from "../../App";

function PoolTable({
  pools,
  onSelect,
  onRefresh,
}: {
  pools: UserPool[];
  onSelect: (pool: UserPool) => any;
  onRefresh: () => any;
}) {
  if (pools.length === 0) {
    return <Box sx={{ paddingTop: "48px" }}>No matching user pools</Box>;
  }

  return (
    <Table
      sx={{
        marginTop: "8px",
        gridTemplateColumns: "1fr 100px min-content",
        "& > :nth-of-type(3n + 1)": {
          "&.cell": {
            cursor: "pointer",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
        "& > :nth-of-type(3n + 2)": {
          justifyContent: "flex-end",
          "& .cell": {
            fontSize: "0.75rem",
          },
        },
      }}
      onRefresh={onRefresh}
    >
      <TableHeader>
        <div>Name</div>
        <div>Created</div>
        <div />
      </TableHeader>
      {pools.map((item, idx) => (
        <TableRow key={idx}>
          <Box
            onClick={() => {
              onSelect(item);
            }}
          >
            {item.name}
          </Box>
          {/* <div>{item.Id}</div> */}
          <Box>
            <DateTime datetime={item.createDate} />
          </Box>
          <Box>
            <IconButton
              size="small"
              disabled
              style={{ marginRight: "8px" }}
              onClick={() => {
                alert("edit");
              }}
            >
              <Tooltip title="Edit Pool">
                <EditIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              size="small"
              onClick={() => {
                onSelect(item);
              }}
            >
              <Tooltip title="Users">
                <UsersIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </TableRow>
      ))}
    </Table>
  );
}

export default function Pools() {
  const { user } = useAuthContext();
  const [enabled, setEnabled] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [unauthorized, setUnauthorized] = useState<boolean>();
  const navigate = useNavigate();
  const { data: pools, refetch: refetchPools } = useQuery<UserPool[]>(
    "pools",
    () => {
      if (!user) {
        return [];
      }
      return listUserPools(user!.token);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      retry: false,
      select: (data) => {
        return data.sort((item1, item2) => {
          const name1 = item1.name.toLowerCase();
          const name2 = item2.name.toLowerCase();
          if (name1 === constants.ADMIN_POOL) {
            return -1;
          } else if (name2 === constants.ADMIN_POOL) {
            return 1;
          }
          if (name1 < name2) {
            return -1;
          } else {
            return 1;
          }
        });
      },
      onError: (error: any) => {
        if (error.statusCode === 401) {
          return setUnauthorized(true);
        }
        console.error(error.data);
      },
    }
  );

  function handleSelect(pool: UserPool) {
    navigate("/users", {
      state: {
        pool: pool,
      },
    });
  }

  useEffect(() => {
    if (!user) {
      return;
    }
    if (!user.userAdmin) {
      setUnauthorized(true);
    } else if (user.customer !== constants.ADMIN_POOL) {
      getUserPool(user.token, user.poolId).then((pool) => {
        navigate("/users", {
          state: {
            pool,
          },
        });
      });
    } else {
      setEnabled(true);
    }
  }, [user]);

  return (
    <Page>
      {unauthorized ? (
        <Centered text="Unauthorized" />
      ) : !pools ? (
        <LoadingSpinner />
      ) : (
        <Stack
          alignSelf="center"
          flex="1"
          width="100%"
          minWidth="600px"
          maxWidth="700px"
          overflow="hidden"
        >
          <Stack direction="row">
            <FilterBox
              value={searchValue}
              flex="1"
              onChange={(value) => {
                setSearchValue(value);
              }}
            />
            <Button
              onClick={() => {
                alert("Creating pools not supported yet");
              }}
            >
              Create Pool
            </Button>
          </Stack>
          <PoolTable
            pools={pools.filter(
              (item) =>
                !searchValue ||
                item.name.toLowerCase().includes(searchValue.toLowerCase())
            )}
            onSelect={handleSelect}
            onRefresh={refetchPools}
          />
        </Stack>
      )}
    </Page>
  );
}
