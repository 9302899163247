/*******************************************************************************
 * (C) Copyright 2023, Westell Technologies, Inc., all rights reserved.
 */
import "../public/style.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { configure as configureApi } from "./lib/api";

configureApi();
const rootElement = document.getElementById("root") as
  | Element
  | DocumentFragment;
const root = createRoot(rootElement);
root.render(<App />);
