/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import { useEffect, useState } from "react";

export function useBrowserStorage(
  key: string
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const [value, setValue] = useState<string | null>(localStorage.getItem(key));

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }, [value]);

  return [value, setValue];
}

export function useBrowserStorageBool(
  key: string
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [value, setValue] = useState<boolean>(
    localStorage.getItem(key) === "true"
  );

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value ? "true" : "false");
    }
  }, [value]);

  return [value, setValue];
}
