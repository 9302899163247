/*******************************************************************************
 * (C) Copyright 2021, 2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactNode, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Page from "../Page";
import { useAuthContext } from "../../components/AuthContext";

import LoginForm from "./LoginForm";
import SendConfirmationForm from "./SendConfirmationForm";
import ChangePasswordForm from "./ChangePasswordForm";

import { AuthForms } from "./Common";

export const DEFAULT_WAIT_TIME = 1400;

export default function AuthPage() {
  const { user: currentUser } = useAuthContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [challengeUser, setChallengeUser] = useState<any>();
  const [form, setForm] = useState<ReactNode>();
  const navigate = useNavigate();

  function setMethod(method: string) {
    setSearchParams((params) => {
      params.set("method", method);
      return params;
    });
  }

  function handleAuth(authPromise: Promise<any>): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      authPromise
        .then((result) => {
          console.log("auth result");
          console.log(result);
          switch (result.challengeName) {
            case "NEW_PASSWORD_REQUIRED":
              console.log("new password required!");
              setChallengeUser(result);
              setMethod(AuthForms.NewPassword);
              break;
            default:
              resolve();
              if (
                (searchParams.get("method") as string) === AuthForms.SendConfirm
              ) {
                setMethod(AuthForms.CodeChangePassword);
                return;
              }
              setTimeout(() => {
                navigate("/", { replace: true });
              }, DEFAULT_WAIT_TIME);
          }
          resolve();
        })
        .catch((error) => {
          if (error.code === "PasswordResetRequiredException") {
            setMethod(AuthForms.SendConfirm);
            resolve();
            return;
          }
          reject(error);
        });
    });
  }

  useEffect(() => {
    let method = searchParams.get("method") as string;
    if (currentUser) {
      if (
        method === AuthForms.SendConfirm ||
        method === AuthForms.CodeChangePassword
      ) {
        navigate("/", { replace: true });
        return;
      }
    } else {
      if (method === AuthForms.ChangePassword) {
        setMethod(AuthForms.Login);
        return;
      }
    }
    if (method === AuthForms.NewPassword && !challengeUser) {
      // This really shouldn't happen
      console.log("no challenge user");
      setMethod(AuthForms.Login);
      return;
    }
    switch (method) {
      case AuthForms.ChangePassword:
      case AuthForms.CodeChangePassword:
        setForm(<ChangePasswordForm handleAuth={handleAuth} />);
        break;
      case AuthForms.SendConfirm:
        setForm(<SendConfirmationForm handleAuth={handleAuth} />);
        break;
      case AuthForms.NewPassword:
        setForm(
          <ChangePasswordForm
            challengeUser={challengeUser}
            handleAuth={handleAuth}
          />
        );
        break;
      default:
        setForm(<LoginForm handleAuth={handleAuth} />);
        break;
    }
  }, [searchParams]);

  if (!form) {
    return null;
  }

  return (
    <Page alignItems="center" justifyContent="center" hideUserButton>
      {form}
    </Page>
  );
}
