/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import Auth from "@aws-amplify/auth";
import { API } from "@aws-amplify/api";
import { Amplify } from "@aws-amplify/core";

import { constants } from "../App";
import {
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "react-query";

export const API_NAME = "InternalAPI";

// Create wrapper types in case we ever change implementation
export interface QueryOptions<TData> extends UseQueryOptions<TData> {}
export declare type QueryResult<TData> = UseQueryResult<TData>;

export interface QueryRange {
  offset: number;
  limit: number;
}

interface BackendResponse<TData> {
  success: boolean;
  data?: TData;
  message?: string;
}

/*******************************************************************************
 * Configure the API endpoint through Amplify
 * This should be called once once for the app.
 */
export function configure() {
  Amplify.configure({
    Auth: {
      region: constants.UX_STACK_REGION,
      userPoolId: constants.UX_COGNITO_USER_POOL,
      userPoolWebClientId: constants.UX_COGNITO_WEB_CLIENT,
    },
    API: {
      endpoints: [
        {
          name: API_NAME,
          endpoint: "/api",
          custom_header: async () => {
            return {
              Authorization: (await Auth.currentSession())
                .getIdToken()
                .getJwtToken(),
            };
          },
        },
      ],
    },
  });
}

/*******************************************************************************
 * AWS API get wrapper
 *
 * @returns The templated result type
 */
export function get<TData>(
  uri: string,
  method: string,
  queryArgs?: object
): Promise<TData> {
  return new Promise<TData>((resolve, reject) => {
    //console.log("api get: " + uri + " - " + method);
    API.get(API_NAME, uri, {
      queryStringParameters: {
        method,
        ...queryArgs,
      },
    })
      .then((result: BackendResponse<TData>) => {
        if (!result.success) {
          reject(result.message);
        }
        resolve(result.data!);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*******************************************************************************
 * useQuery wrapper around get call
 *
 * @param queryKey
 * @param uri
 * @param method
 * @param queryArgs
 * @param options
 *
 * @return TData
 */
export function getQuery<TData>(
  queryKey: QueryKey,
  queryFunc: () => Promise<TData>,
  options?: QueryOptions<TData>
): QueryResult<TData> {
  return useQuery<TData>(queryKey, queryFunc, {
    refetchOnWindowFocus: false,
    ...options,
  });
}

/*******************************************************************************
 * post
 */
export function post<TResult>(
  uri: string,
  method: string,
  queryArgs?: object,
  body?: any
): Promise<TResult> {
  return API.post(API_NAME, uri, {
    queryStringParameters: {
      method,
      ...queryArgs,
    },
    body,
  });
}

/*******************************************************************************
 * Send job control
 *
 * @param user the name of the user exercising this control
 * @param neName the name of the ne related to this control
 * @param type the type of job control
 * @param source the umiSource for this job control
 * @returns the response data
export async function sendJobControl(
  user: string,
  neName: string,
  type: string,
  source: string
): Promise<any[]> {
  const response = await API.get(API_NAME, "/controls", {
    queryStringParameters: {
      controlType: "job",
      username: user,
      neName: neName,
      jobType: type,
      umiSource: source,
    },
  });
  return response.data;
}
 */

/*******************************************************************************
 * Send maintenance control
 *
 * @param user the name of the user exercising this control
 * @param neName the name of the ne related to this control
 * @param status the maintenance status (active or maintenance) to set for element
 * @returns the response data
export async function sendMaintenanceControl(
  user: string,
  neName: string,
  status: SNE
): Promise<any[]> {
  const response = await API.get(API_NAME, "/controls", {
    queryStringParameters: {
      controlType: "maintenance",
      username: user,
      neName: neName,
      status: status,
    },
  });
  return response.data;
}
 */

export default {
  get,
  getQuery,
  post,
};
