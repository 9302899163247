/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";

export interface SessionUser {
  token: string;
  poolId: string;
  email: string;
  name: string;
  customer: string;
  tenant: string;
  initials: string;
  userAdmin: boolean;
  //attributes: { [key: string]: string | boolean };
}

export interface AuthInformation {
  user: SessionUser | null;
  changePassword: (previousPassword: string, password: string) => Promise<any>;
  logout: () => void;
}

const AuthContext = createContext<AuthInformation>({
  user: null,
  changePassword: async () => {},
  logout: () => {},
});
export function useAuthContext() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: { children: ReactNode }) {
  const [userInfo, setUserInfo] = useState<SessionUser | null>(null);
  const [loaded, setLoaded] = useState(false);
  const cognitoUser = useRef<any>();

  async function getUser(): Promise<SessionUser> {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((awsUser) => {
          cognitoUser.current = awsUser;
          const name = awsUser.attributes["name"];
          const names = name.split(" ");
          const initials = names[0][0] + names[names.length - 1][0];

          resolve({
            token: awsUser.signInUserSession.accessToken.jwtToken,
            poolId: awsUser.pool.userPoolId,
            email: awsUser.attributes.email,
            name,
            customer: awsUser.attributes["custom:customer"],
            tenant: awsUser.attributes["custom:tenant_code"],
            initials,
            userAdmin:
              (
                awsUser.attributes["custom:user_admin"] as string
              )?.toUpperCase() === "YES",
            //attributes: awsUser.attributes,
          });
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  async function refreshUser() {
    getUser()
      .then((user) => {
        setUserInfo(user);
      })
      .catch((error) => {
        setUserInfo(null);
      })
      .finally(() => {
        // we've at least attempted to load
        setLoaded(true);
      });
  }

  function handleAuth({ payload }: { payload: any }) {
    switch (payload.event) {
      case "signIn":
      case "signUp":
      case "signIn_failure":
        refreshUser();
        break;
      case "signOut":
        setUserInfo(null);
        break;
    }
  }

  function handleLogout() {
    Auth.signOut();
  }

  async function handleChangePassword(
    previousPassword: string,
    password: string
  ) {
    if (!userInfo || !cognitoUser.current) {
      console.log("No user to update");
      return;
    }

    return Auth.changePassword(cognitoUser.current, previousPassword, password);
  }

  useEffect(() => {
    const hubListenerCancelToken = Hub.listen("auth", handleAuth);
    refreshUser();
    return () => {
      hubListenerCancelToken();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: userInfo,
        changePassword: handleChangePassword,
        logout: handleLogout,
      }}
    >
      {loaded && children}
    </AuthContext.Provider>
  );
}
