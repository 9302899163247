/*******************************************************************************
 * (C) Copyright 2022, Westell Technologies, Inc., all rights reserved.
 */
import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  FormControlLabel,
  Modal,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Person as UserIcon } from "@mui/icons-material";

import { adminCreateUser, adminUpdateUser } from "../../lib/usersapi";

import { useAuthContext } from "../../components/AuthContext";
import {
  ValidationTextField,
  useValidation,
} from "../../components/ValidationTextField";
import { User, UserPool } from "../../lib/backend/users";
import { constants } from "../../App";

const regExTenant = RegExp("^[a-zA-Z0-9 ]*$");

interface UserModalProps {
  pool: UserPool;
  user: User | string | null;
  onClose: (refetch?: boolean) => any;
}
export default function UserModal({ pool, user, onClose }: UserModalProps) {
  const auth = useAuthContext();
  const currentUser = auth.user!;
  const [editUser, setEditUser] = useState<User>();
  const [saving, setSaving] = useState<boolean>();
  const validation = useValidation();

  function handleSave() {
    // this shouldn't happen
    if (!editUser) {
      return;
    }
    if (validation.hasErrors) {
      validation.setVisible(true);
      return;
    }

    setSaving(true);
    const prom =
      user === "new"
        ? adminCreateUser(currentUser.token, pool.id, editUser)
        : adminUpdateUser(currentUser.token, pool.id, editUser);
    prom
      .then(() => {
        onClose(true);
      })
      .catch((error) => {
        alert("error: " + JSON.stringify(error));
        onClose(false);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  useEffect(() => {
    user &&
      setEditUser(
        user === "new"
          ? {
              customer: pool.name,
              email: "",
              name: "",
              userAdmin: pool.name === constants.ADMIN_POOL,
              tenant: "",
              createDate: new Date(),
              enabled: false,
              status: "unknown",
            }
          : (user as User)
      );
  }, [user]);

  if (!editUser) return null;

  return (
    <Modal
      open={Boolean(user)}
      closeAfterTransition
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card sx={{ width: "400px" }}>
        <CardHeader
          avatar={
            <Avatar sx={{ background: "white" }}>
              <UserIcon sx={{ color: "#585656" }} />
            </Avatar>
          }
          title={user === "new" ? "Add User" : "Edit User"}
        />
        <CardContent>
          <ValidationTextField
            name="email"
            label="Email address"
            value={editUser.email}
            fullWidth
            required
            emailAddress
            disabled={saving || user !== "new"}
            autoFocus
            margin="normal"
            onChangeValue={(_, value) => {
              validation.setVisible(false);
              setEditUser((prev) => prev && { ...prev, email: value });
            }}
            validation={validation}
          />
          <ValidationTextField
            name="name"
            label="Name"
            value={editUser.name}
            required
            fullWidth
            disabled={saving}
            margin="normal"
            onChangeValue={(_, value) => {
              validation.setVisible(false);
              setEditUser((prev) => prev && { ...prev, name: value });
            }}
            validation={validation}
            onValidate={(value) => {
              if (!value.trim().includes(" ")) {
                return "Must supply first and last name";
              }
            }}
          />
          {user === "new" && (
            <Typography padding="4px 0 0 8px" fontWeight="bold" color="#339900">
              Temporary password will be emailed to user
            </Typography>
          )}
          {pool.name === constants.ADMIN_POOL && (
            <ValidationTextField
              name="customer"
              label="Customer"
              value={editUser.customer}
              required
              fullWidth
              disabled={saving}
              margin="normal"
              onChangeValue={(_, value) => {
                validation.setVisible(false);
                setEditUser((prev) => prev && { ...prev, customer: value });
              }}
              validation={validation}
            />
          )}
          <ValidationTextField
            name="tenant"
            label={
              "Tenant (" +
              (pool.name === constants.ADMIN_POOL
                ? "this should probably be blank"
                : "optional") +
              ")"
            }
            value={editUser.tenant}
            fullWidth
            disabled={saving}
            margin="normal"
            onChangeValue={(_, value) => {
              validation.setVisible(false);
              setEditUser((prev) => prev && { ...prev, tenant: value });
            }}
            validation={validation}
            onValidate={(value) => {
              if (!regExTenant.test(value)) {
                return "Specify space delimited list";
              }
            }}
          />
          {pool.name === constants.ADMIN_POOL && (
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editUser.userAdmin}
                    onChange={(evt) => {
                      validation.setVisible(false);
                      setEditUser(
                        (prev) =>
                          prev && { ...prev, userAdmin: evt.target.checked }
                      );
                    }}
                  />
                }
                label="User Administrator"
                sx={{ padding: "8px 0" }}
              />
            </Box>
          )}
          <Stack direction="row" justifyContent="space-evenly" paddingTop="8px">
            <Button
              disabled={saving}
              sx={{ width: "120px" }}
              onClick={() => {
                if (confirm("Cancel, are you sure?")) {
                  onClose();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: "120px", marginLeft: "16px" }}
              onClick={handleSave}
            >
              {saving ? <CircularProgress /> : "Save"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Modal>
  );
}
