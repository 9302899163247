/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React, { ReactElement } from "react";
import { useAuthContext } from "./components/AuthContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { SessionProvider } from "./SessionContext";
import AuthPage from "./pages/auth/AuthPage";
import PoolsPage from "./pages/pools/PoolsPage";
import UsersPage from "./pages/users/UsersPage";

function RequireAuth({ children }: { children: ReactElement }) {
  const { user } = useAuthContext();
  return user ? children : <Navigate to="/auth" />;
}

function MyRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/" element={<Navigate to="/pools" replace />} />
      <Route
        path="/pools"
        element={
          <RequireAuth>
            <PoolsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/users"
        element={
          <RequireAuth>
            <UsersPage />
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default function Content() {
  return (
    <SessionProvider>
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
    </SessionProvider>
  );
}
