/*******************************************************************************
 * (C) Copyright 2022, Westell Technologies, Inc., all rights reserved.
 */
import API from "./api";
import { User, UserPool } from "./backend/users";

/******************************************************************************/
/**
 * API function for listing user pools
 */
export async function listUserPools(
  accessToken: string,
  maxResults?: number
): Promise<UserPool[]> {
  return API.get<UserPool[]>("/users", "listUserPools", {
    accessToken,
    maxResults,
  }).then((result) =>
    result.map<UserPool>((pool) => ({
      ...pool,
      createDate: new Date(pool.createDate),
    }))
  );
}

/******************************************************************************/
/**
 * API function for listing users
 */
export async function getUserPool(
  accessToken: string,
  userPoolId: string
): Promise<UserPool> {
  return API.get<UserPool>("/users", "getUserPool", {
    accessToken,
    userPoolId,
  }).then((pool) => ({
    ...pool,
    createDate: new Date(pool.createDate),
  }));
}

/******************************************************************************/
/**
 * API function for listing users
 */
export async function listUsers(
  accessToken: string,
  userPoolId: string
): Promise<User[]> {
  return API.get<User[]>("/users", "listUsers", {
    accessToken,
    userPoolId,
  }).then((users) =>
    users.map((user) => ({
      ...user,
      createDate: new Date(user.createDate),
    }))
  );
}

/******************************************************************************/
/**
 *
 */
export async function adminCreateUser(
  accessToken: string,
  userPoolId: string,
  user: User
): Promise<any> {
  return API.post(
    "/users",
    "adminCreateUser",
    {
      accessToken,
    },
    {
      poolId: userPoolId,
      ...user,
    }
  );
}

/******************************************************************************/
/**
 *
 */
export async function adminUpdateUser(
  accessToken: string,
  userPoolId: string,
  user: User
): Promise<any> {
  return API.post(
    "/users",
    "adminUpdateUser",
    {
      method: "adminUpdateUser",
      accessToken,
    },
    {
      poolId: userPoolId,
      ...user,
    }
  );
}

/******************************************************************************/
/**
 *
 */
export async function adminDeleteUser(
  accessToken: string,
  userPoolId: string,
  email: string
): Promise<[]> {
  return API.get("/users", "adminDeleteUser", {
    accessToken,
    userPoolId,
    email,
  });
}

/******************************************************************************/
/**
 *
 */
export async function adminEnableUser(
  accessToken: string,
  userPoolId: string,
  email: string
): Promise<[]> {
  return API.get("/users", "adminEnableUser", {
    accessToken,
    userPoolId,
    email,
  });
}

/******************************************************************************/
/**
 *
 */
export async function adminDisableUser(
  accessToken: string,
  userPoolId: string,
  email: string
): Promise<[]> {
  return API.get("/users", "adminDisableUser", {
    accessToken,
    userPoolId,
    email,
  });
}

/******************************************************************************/
/**
 *
 */
export async function adminExpireUserPassword(
  accessToken: string,
  userPoolId: string,
  email: string
): Promise<[]> {
  return API.get("/users", "adminExpireUserPassword", {
    accessToken,
    userPoolId,
    email,
  });
}

/******************************************************************************/
/**
 *
 */
export async function resendPasswordConfirmation(
  accessToken: string,
  clientId: string,
  email: string
): Promise<[]> {
  return API.get("/users", "resendPasswordConfirmation", {
    accessToken,
    clientId,
    email,
  });
}

/******************************************************************************/
/**
 *
 */
export async function updateUserAttributes(
  accessToken: string,
  attributes: { [key: string]: string | boolean }
): Promise<void> {
  return API.post(
    "/users",
    "updateUserAttributes",
    {
      accessToken,
    },
    {
      accessToken,
      attributes,
    }
  );
}
