/*******************************************************************************
 * (C) Copyright 2022-2023, Westell Technologies, Inc., all rights reserved.
 */
import React from "react";
import { Box, BoxProps } from "@mui/material";

interface DateTimeProps extends BoxProps {
  datetime: Date;
  removeSameDay?: boolean;
}

/*******************************************************************************
 *
 */
export default function DateTime({
  datetime,
  removeSameDay = true,
  ...props
}: DateTimeProps) {
  const today = new Date();
  const sameDay =
    datetime.getFullYear() == today.getFullYear() &&
    datetime.getMonth() == today.getMonth() &&
    datetime.getDate() == today.getDate();

  return (
    <Box fontSize="0.75rem" textAlign="end" {...props}>
      {!removeSameDay ||
        (!sameDay && (
          <>
            {datetime.toLocaleDateString()}
            <br />
          </>
        ))}
      <>{datetime.toLocaleTimeString()}</>
    </Box>
  );
}
